var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c("nxp-search", {
        attrs: { "config-list": _vm.searchConfigList },
        on: { clickSearch: _vm.clickSearch, resetForm: _vm.resetForm },
      }),
      _c("select-table", {
        attrs: {
          accessories: _vm.nxpTabelAccessories,
          "object-span-method": _vm.objectSpanMethod,
          "only-key-id": "id",
          "show-pagination": false,
          "table-columns": _vm.tableColumns,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "w-theight": 320,
        },
        on: { fetchData: _vm.fetchData },
      }),
      _c("addEdit", {
        ref: "edit",
        attrs: { "status-options": _vm.statusOptions },
        on: { "fetch-data": _vm.clickSearch },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }