<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      :object-span-method="objectSpanMethod"
      only-key-id="id"
      :show-pagination="false"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
    <!-- <div class="block">
      <p>使用 scoped slot</p>
      <el-tree :data="tableData" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            <span v-if="node.level == 1" style="color:#1665d8;">用户</span>
            <span v-if="node.level == 2" style="color:#7fbb00;">项目</span>
            <span v-if="node.level == 3" style="color:#00a5f0;">报告</span>
            <span> {{ node.label }}</span>
          </span>
          <span>
            <el-button type="text" size="mini" @click="() => append(data)">
              Append
            </el-button>
            <el-button type="text" size="mini" @click="() => remove(node, data)">
              Delete
            </el-button>
          </span>
        </span>
      </el-tree>

    </div> -->

    <addEdit ref="edit" :status-options="statusOptions" @fetch-data="clickSearch" />
  </div>
</template>

<script>
import {
  queryReportDataList,
  againCreateReport,
} from "@/project/eztree/baseApi/commonApi";
import { getArrSort } from "@/project/eztree/baseTool/mapStr";
// 成团状态：1,进行中，2已成团，3已终止
const statusOptions = [
  { label: "失败", value: "0" },
  { label: "生成中", value: "1" },
  { label: "已生成", value: "2" },
];
export default {
  name: "Report",
  components: {
    addEdit: () => import("./addEdit"),
  },
  props: {
    project_id: {
      type: String,
    },
  },
  data() {
    return {
      statusOptions: statusOptions,
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "select",
          showName: false,
          model: "",
          param: "status",
          label: "状态",
          options: statusOptions,
        },

        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 999999,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
        },
        {
          label: "项目名称",
          prop: "project_name",
        },
        { label: "报告名称", prop: "report_name" },
        {
          label: "状态",
          prop: "status",
          width: "100px",
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.status == "0"
                      ? "danger"
                      : params.row.status == "1"
                      ? "warn"
                      : params.row.status == "2"
                      ? "success"
                      : "",
                  effect: "plain",
                },
              },
              params.row.status == "0"
                ? "失败"
                : params.row.status == "1"
                ? "生成中"
                : params.row.status == "2"
                ? "已生成"
                : ""
            );
          },
        },
        {
          label: "创建时间",
          width: "160px",
          prop: "created_at",
        },
        {
          label: "更新时间",
          width: "160px",
          prop: "updated_at",
        },
        {
          label: "操作",
          fixed: "right",
          width: "180px",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {},
              },
              [
                params.row.status != 2
                  ? h(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          params: params.row,
                        },
                        on: {
                          ...this.$listeners,
                          click: () => {
                            let param = Object.assign(
                              {},
                              {
                                report_id: params.row.report_id,
                                user_id: params.row.user_id,
                                project_id: params.row.project_id,
                              }
                            );
                            againCreateReport(param).then(({ code, data }) => {
                              if (code == "200") {
                                this.initQuery();
                                this.$baseMessage(
                                  "操作成功",
                                  "success",
                                  "vab-hey-message-success"
                                );
                              }
                            });
                          },
                        },
                      },
                      "重新生成"
                    )
                  : h(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          params: params.row,
                        },
                        on: {
                          ...this.$listeners,
                          click: () => {
                            this.$refs["edit"].showEdit(params.row, "view");
                          },
                        },
                      },
                      "预览"
                    ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "edit");
                      },
                    },
                  },
                  "编辑"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.$refs["edit"].showEdit(params.row, "delete");
                      },
                    },
                  },
                  "删除"
                ),
              ]
            );
          },
        },
      ];
      return columns;
    },
  },
  mounted() {},
  created() {},
  methods: {
    // 这个方法是 element-ui提供的单元格合并方法
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 合并单元格
      switch (columnIndex) {
        case 1:
          return this.mergeComon("shop_id", rowIndex);
          // eslint-disable-next-line no-unreachable
          break;
        case 2:
          return this.mergeComon("project_id", rowIndex);
      }
    },
    //这个方法 重要 ，这个方法是将数组中的对象 按照分类进行合并单元格的方法（直接复制使用（引用的别人的 没仔细研究））
    mergeComon(id, rowIndex) {
      // 合并单元格
      var idName = this.tableData[rowIndex][id];
      if (rowIndex > 0) {
        // eslint-disable-next-line eqeqeq
        if (this.tableData[rowIndex][id] != this.tableData[rowIndex - 1][id]) {
          var i = rowIndex;
          var num = 0;
          while (i < this.tableData.length) {
            if (this.tableData[i][id] === idName) {
              i++;
              num++;
            } else {
              i = this.tableData.length;
            }
          }
          return {
            rowspan: num,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 1,
          };
        }
      } else {
        let i = rowIndex;
        let num = 0;
        while (i < this.tableData.length) {
          if (this.tableData[i][id] === idName) {
            i++;
            num++;
          } else {
            i = this.tableData.length;
          }
        }
        return {
          rowspan: num,
          colspan: 1,
        };
      }
    },

    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      params.project_id = this.project_id;
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryReportDataList(params).then(({ code, data }) => {
        if (code == "200") {
          let pageData = (data && data.list) || [];
          this.tableData = pageData;
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },

    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
